import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import TableMarketingChannel from '../../components/MarketingChannel/TableMarketingChannel.jsx';
import Filter from "../../components/MarketingChannel/TraderFilter.jsx";
import Nav from '../../components/MarketingChannel/Nav.jsx'
import { getPaginateTradersByIB } from "../../api/trader.jsx";
import { DEFAULT_LIMIT } from '../../constant/pagination.jsx'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { listMarketingChannel } from "../../api/marketing-channel.jsx";


const DashboardMarketingChannel = () => {
    const [traders, setTraders] = useState([]),
        [loading, setLoading] = useState(false),
        [page, setPage] = useState(1),
        [total_page, setTotalPage] = useState(0)

    async function fetchPaginateTraders() {
        setLoading(true)
        try {
            const result = await listMarketingChannel(DEFAULT_LIMIT, page)
            setTraders(result.data)
            setTotalPage(result.total_page)
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchPaginateTraders()
    }, [page])

    const location = useLocation()
    const navigate = useNavigate()
    const toastShown = useRef(false); // Track if toast has been shown

    useEffect(() => {
        if (location.state?.toastMessage && !toastShown.current) {
            toastShown.current = true; // Mark toast as shown
            toast(location.state.toastMessage, {
                position: "top-center",
                hideProgressBar: true,
                className: 'bg-success text-white'
            });

            // Clear toastMessage from the state
            navigate('/marketing-channel', { replace: true, state: {} });
        }
    }, [location.state]);

    return (
        <React.Fragment>
            <Helmet>
                <title>Marketing Channel - Zexter Admin Dashboard</title>
            </Helmet>
            <div className="page-content">
                <ToastContainer autoClose={5000} />
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Nav />
                        </Col>
                        <Col lg={12}>
                            <Filter page={page} setTraders={setTraders} toast={toast} setLoading={setLoading} setTotalPage={setTotalPage} />
                        </Col>
                        <Col lg={12}>
                            <TableMarketingChannel setPage={setPage} page={page} total_page={total_page} traders={traders} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default DashboardMarketingChannel;
