//position.jsx
import axios from "axios";

//List Position
export const getListPosition = async (limit, page) => {
  try {
    const result = await axios.get(
      `/v1/position/admin/list?limit=${limit}&page=${page}`
    );
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getListRebateByIBID = async (limit, offset, filters = {}) => {
  try {
    const result = await axios.get(`/v1/ib/rebate/filter`, {
      params: {
        limit,
        offset,
        email: filters.email || "",
        id: filters.id || "",
        symbol: filters.symbol || "",
      },
    });
    console.log(result);
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

//Search Position
export const searchPosition = async (id) => {
  try {
    const result = await axios.get(`/v1/ib/admin/admins/${id}`);
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};
