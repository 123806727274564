import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import TableTransaction from './TableTransacation.jsx';
import Filter from "./filter.jsx";
import Nav from './nav.jsx'
import Breadcrump from '../../../components/Common/BreadCrumb.js'
import { listTransaction } from '../../../api/payment.jsx'
import { ToastContainer } from "react-toastify";
import { DEFAULT_LIMIT } from "../../../constant/pagination.jsx";

const TransactionManagement = () => {
    const [transaction, setTransaction] = useState([]),
        [loading, setLoading] = useState(false),
        [page, setPage] = useState(1),
        [total_page, setTotalPage] = useState(0)


    async function getWallets() {
        setLoading(true)
        try {
            const result = await listTransaction(DEFAULT_LIMIT, page)
            console.log(result);
            setTransaction(result)
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    useEffect(() => {
        getWallets()
    }, [])

    return (
        <React.Fragment>
            <Helmet>
                <title>Transaction History - Zexter IB Dashboard</title>
            </Helmet>
            <div className="page-content">
                <ToastContainer autoClose={3000} />
                <Container fluid>
                    <Breadcrump title={"Transaction History"} />
                    <Row>
                        {/* <Col lg={12}>
                            <Nav />
                        </Col>
                        <Col lg={12}>
                            <Filter />
                        </Col> */}
                        <Col lg={12}>
                            <TableTransaction wallets={transaction} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default TransactionManagement;
