import React, { useState } from 'react'
import { Card, CardBody, Label, Col, Input, Row } from 'reactstrap'
import { listMarketingChannelFilter } from '../../api/marketing-channel'
import { DEFAULT_LIMIT } from '../../constant/pagination'

export default function TraderFilter({ setTraders, page, toast, setTotalPage, setLoading }) {
    const [id, setID] = useState("")
    const [name, setName] = useState("")
    const [platform, setPlatform] = useState("")

    async function filterListMarketingChannel(e) {
        e.preventDefault()
        setLoading(true)
        try {
            const result = await listMarketingChannelFilter(DEFAULT_LIMIT, page, id, name, platform)
            setTraders(result.data)
            setTotalPage(result.total_page)
            setLoading(false)
        } catch (error) {
            toast(error, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' })
        }
    }

    return (
        <Card>
            <CardBody>
                <form onSubmit={filterListMarketingChannel}>
                    <Row>
                        <Col lg={4} md={6}>
                            <div className="mb-3">
                                <Label htmlFor="choices-multiple-groups" className="form-label text-muted">
                                    Marketing Channel ID
                                </Label>
                                <Input value={id} onChange={e => setID(e.target.value)} type="text" className="form-control" id="placeholderInput" placeholder="ID" />
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="mb-3">
                                <Label htmlFor="choices-multiple-groups" className="form-label text-muted">
                                    Name
                                </Label>
                                <Input value={name} onChange={e => setName(e.target.value)} type="text" className="form-control" id="placeholderInput" placeholder="name" />
                            </div>
                        </Col>
                        <Col lg={4} md={6}>
                            <div className="mb-3">
                                <Label htmlFor="choices-multiple-groups" className="form-label text-muted">
                                    Platform
                                </Label>
                                <Input value={platform} onChange={e => setPlatform(e.target.value)} type="text" className="form-control" id="placeholderInput" placeholder="platform" />
                            </div>
                        </Col>
                        <Col lg={12} className='text-end'>
                            <button type='submit' className='btn btn-success'>Search</button>
                        </Col>
                    </Row>
                </form>
            </CardBody>
        </Card>
    )
}
