import axios from "axios";
import { setAuthToken } from "../middleware/setAuthToken";

export async function Login(body) {
  try {
    const { Token } = await axios.post("/v1/ib/login", body);

    // localstorage
    localStorage.setItem("jwt", "Bearer " + Token);
    //set token ot axios
    setAuthToken("Bearer " + Token);

    return Token;
  } catch (error) {
    console.log(error, "error");
    return Promise.reject(error);
  }
}

//List Admin
export async function getPaginateAdminsByIB(limit, page, filters = {}) {
  try {
    const result = await axios.get(`/v1/ib/admins/filter`, {
      params: {
        limit,
        page,
        username: filters.email || "",
        id: filters.id || "",
      },
    });
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
}

//create admin
export async function createAdmin(adminData) {
  try {
    const response = await axios.post("/v1/ib/admin/user", adminData);
    return response.data;
  } catch (error) {
    console.log(error, "error");
    return Promise.reject(error);
  }
}

//ib change password
export async function changePassword(passwordData) {
  try {
    const response = await axios.patch(
      `/v1/ib/admin/password`,
      passwordData
    );
    return response.data;
  } catch (error) {
    console.log(error, "error");
    return Promise.reject(error);
  }
}
