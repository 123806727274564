import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import { getPaginateAdminsByIB } from "../../api/admin";
import { DEFAULT_LIMIT } from "../../constant/pagination";
import TableAdmin from "../../components/AdminManagement/TableAdmin.jsx";
import { AdminFilter } from "../../components/AdminManagement/AdminFilter.jsx";
import Nav from "../../components/AdminManagement/Nav.jsx";

const DashboardAdmin = () => {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total_page, setTotalPage] = useState(0);
  const [filters, setFilters] = useState({
    id: "",
    email: "",
  });

  const fetchPaginateAdmins = async () => {
    setLoading(true);
    try {
      const result = await getPaginateAdminsByIB(DEFAULT_LIMIT, page, filters);
      setAdmins(result.data || []);
      setTotalPage(result.total_page || 0);
    } catch (error) {
      console.error("Error fetching admins:", error);
      setAdmins([]);
      setTotalPage(0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPaginateAdmins();
  }, [page, filters]);

  const handleSearch = (searchFilters) => {
    setFilters(searchFilters);
    setPage(1); // รีเซ็ตกลับไปหน้า 1 เมื่อค้นหาใหม่
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Overview - Zexter IB Dashboard</title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Nav />
            </Col>
            <Col lg={12}>
              <AdminFilter onSearch={handleSearch} />
            </Col>
            <Col lg={12}>
              <TableAdmin
                setPage={setPage}
                page={page}
                total_page={total_page}
                traders={admins}
                loading={loading}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardAdmin;
