import axios from "axios";

export async function getPositionHistory(user_id) {
  try {
    const response = await axios.get(
      `/v1/backoffice/ib/user/${user_id}/positions?per_page=1&page=1`
    );
    return response;
  } catch (error) {
    console.error("Error fetching profit chart data:", error);
    throw error;
  }
}
