import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import Breadcrump from "../../components/Common/BreadCrumb.js";
import { getListRebateByIBID } from "../../api/position.jsx";
import FilterPosition from "../../components/Filter/FilterPosition.jsx";
import TablePosition from "../../components/Table/TablePosition.jsx";
import { DEFAULT_LIMIT } from "../../constant/pagination.jsx";

const DashboardPosition = () => {
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [filters, setFilters] = useState({
    id: "",
    email: "",
    symbol: "",
  });

  const fetchPositions = async () => {
    setLoading(true);
    try {
      const offset = (page - 1) * DEFAULT_LIMIT;
      const result = await getListRebateByIBID(DEFAULT_LIMIT, offset, filters);

      setPositions(result.data || []);
      setTotalPage(result.total_page || 0);
    } catch (error) {
      console.error("Error fetching positions:", error);
      setPositions([]);
      setTotalPage(0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPositions();

    const interval = setInterval(fetchPositions, 3000);
    return () => clearInterval(interval);
  }, [page, filters]);

  const handleSearch = (searchFilters) => {
    setFilters(searchFilters);
    setPage(1);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Position Management - Zexter Admin Dashboard</title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          <Breadcrump title={"Position Management"} />
          <Row>
            <Col lg={12}>
              <FilterPosition onSearch={handleSearch} />
            </Col>
            <Col lg={12}>
              <TablePosition
                positions={positions}
                page={page}
                totalPage={totalPage}
                onSetPage={setPage}
                loading={loading}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardPosition;
