import axios from "axios";

export async function getAlltraders() {
  try {
    const result = await axios.get("/v1/user/admin");
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getPaginateTraders(limit, page) {
  try {
    const result = await axios.get(`/v1/user/list?limit=${limit}&page=${page}`);
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getPaginateTradersByIB(
  limit,
  offset,
  email = "",
  id = ""
) {
  const response = await axios.get(`/v1/ib/traders/filter`, {
    params: {
      limit,
      offset,
      email,
      id,
    },
  });
  return response;
}

export async function addTraderRealBalance(body) {
  try {
    const result = await axios.patch("/v1/user/balance/real/add", body);
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function deductTraderRealBalance(body) {
  try {
    const result = await axios.patch("/v1/user/balance/real/deduct", body);
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
}
