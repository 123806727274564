import axios from "axios";

export async function ShowProfitchart({ time_frame }) {
  try {
    const response = await axios.get(`/v1/backoffice/ib/profit_chart`, {
      params: {
        time_frame,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching profit chart data:", error);
    throw error;
  }
}
