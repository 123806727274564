import axios from "axios";

export async function createSubIB(body) {
    try {
        const result = await axios.post("/v1/ib/sub", body);
        return result;
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function getSubIB(limit, page) {
    try {
        const result = await axios.get(`/v1/ib/sub/list?limit=${limit}&page=${page}`);
        return result;
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function getSubIBWithFilter(limit, page, id, name) {
    try {
        const result = await axios.get(`/v1/ib/sub/list/filter?limit=${limit}&page=${page}&id=${id}&name=${name}`);
        return result;
    } catch (error) {
        return Promise.reject(error);
    }
}

export async function getSubIBSitemap() {
    try {
        const result = await axios.get("/v1/ib/sub/sitemap");
        return result;
    } catch (error) {
        return Promise.reject(error);
    }
}