import axios from "axios";

export async function getWithdrawnRate() {
  try {
    const result = await axios.get("/v1/currency/withdrawn/rate");
    return result;
  } catch (error) {
    console.log(error);
  }
}
