export function onChangeAllowNumberOnly(value, cb) {
    const re = /^[0-9\b.]+$/;

    // Check if the value is blank
    if (value === "") {
        cb(value);
        return; // Allow empty input
    }

    // If the value starts with a '.', prepend '0'
    if (value.startsWith('.')) {
        value = '0' + value;
    }

    // Validate the regex and ensure there is at most one decimal point
    const isValidNumber = re.test(value) && (value.match(/\./g) ? value.match(/\./g).length <= 1 : true);

    // If valid, further check for decimal places
    if (isValidNumber) {
        const parts = value.split('.');
        // Allow only up to 2 decimal places
        if (parts.length === 1 || (parts.length === 2 && parts[1].length <= 2)) {
            cb(value);
        }
    }
}