import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import Widget from "./Widgets";
import HeaderWidget from "./HeaderWidget";
import RecentOpenedPositions from "./OpeningPositions";
import ProfitLossChart from "./ProfitLossChart";
import Section from "./Section";
import PlatformVisits from "./PlatformVisits";
import { Helmet } from "react-helmet";
import { ShowOverviewData } from "../../store/overview/action";
import { getWithdrawnRate } from "../../store/withdrawrate/action";
import Wrapper from "../../components/Common/Wrapper";
import moment from "moment-timezone";

const DashboardOverview = () => {
  const [overviewData, setOverviewData] = useState();
  const [currency, setCurrency] = useState(false);
  const [withdrawnRate, setWithdrawnRate] = useState();
  const [loading, setLoading] = useState(true); // เพิ่ม loading state
  const [rate, setRate] = useState(1);
  const [date, setDate] = useState(
    moment().tz("Asia/Bangkok").startOf("day").format()
  );
  const [dateTo, setDateTo] = useState(
    moment().tz("Asia/Bangkok").startOf("day").format()
  );
  const [dateFrom, setDateFrom] = useState(
    moment().tz("Asia/Bangkok").startOf("day").format()
  );
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const startDate = moment.utc(dateTo).toISOString();
        const endDate = moment.utc(dateFrom).add(1, "days").toISOString();

        const [overviewResult, withdrawnResult] = await Promise.all([
          ShowOverviewData({
            from: startDate,
            to: endDate,
          }),
          getWithdrawnRate(),
        ]);
        setOverviewData(overviewResult);
        setWithdrawnRate(withdrawnResult);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [dateFrom, dateTo]);
  useEffect(() => {
    setRate(currency ? withdrawnRate : 1);
  }, [currency, withdrawnRate]);
  const LoadingSpinner = () => (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "400px" }}
    >
      <div className="text-center">
        <Spinner className="me-2" color="primary">
          Loading...
        </Spinner>
        <div className="mt-2 text-muted">Loading data...</div>
      </div>
    </div>
  );
  const handleDateChange = (selectedDates) => {
    if (selectedDates && selectedDates[0]) {
      setDateTo(selectedDates[0].toISOString());
    }
    if (selectedDates && selectedDates[1]) {
      setDateFrom(selectedDates[1].toISOString());
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Overview - Zexter IB Dashboard</title>
      </Helmet>
      <Wrapper>
        <Container fluid>
          <Row>
            <Col>
              <Section
                currency={currency}
                setCurrency={setCurrency}
                date={date}
                handleDateChange={handleDateChange}
              />

              {loading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Row>
                    <HeaderWidget
                      data={overviewData}
                      currency={currency}
                      rate={rate}
                    />
                  </Row>

                  <Row>
                    <Widget
                      data={overviewData}
                      currency={currency}
                      rate={rate}
                    />
                  </Row>

                  <Row>
                    <Col xl={8}>
                      <ProfitLossChart
                        currency={currency}
                        data={overviewData?.ProfitLossSeries}
                      />
                    </Col>
                    <PlatformVisits data={overviewData?.UserByPlatform} />
                  </Row>

                  <Row>
                    <RecentOpenedPositions
                      data={overviewData?.RecentOpenedPosition || ""}
                    />
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </React.Fragment>
  );
};

export default DashboardOverview;
