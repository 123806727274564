import { useState } from "react";
import Wrapper from "../../components/Common/Wrapper";
import { changePassword } from "../../api/admin";

const ChangePassword = () => {
  const [message, setMessage] = useState({ type: "", content: "" });
  const [formData, setFormData] = useState({
    password: "",
    repassword: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setMessage({ type: "", content: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.repassword) {
      setMessage({ type: "danger", content: "Passwords do not match" });
      return;
    }

    try {
      await changePassword(formData);
      setMessage({
        type: "success",
        content: "Password changed successfully!",
      });
      setFormData({
        password: "",
        repassword: "",
      });
    } catch (error) {
      setMessage({
        type: "danger",
        content: error.response?.data?.message || "Failed to change password",
      });
    }
  };

  return (
    <Wrapper>
      <div className="card border-secondary">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h5 className="mb-0">Change Password</h5>
          </div>

          {message.content && (
            <div className={`alert alert-${message.type} mb-3`} role="alert">
              {message.content}
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="mb-4">
                  <div className="mb-3">
                    <label className="form-label">New Password</label>
                    <input
                      type="password"
                      name="password"
                      className="form-control bg-dark text-muted"
                      value={formData.password}
                      onChange={handleInputChange}
                      placeholder="Enter new password"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Confirm Password</label>
                    <input
                      type="password"
                      name="repassword"
                      className="form-control bg-dark text-muted"
                      value={formData.repassword}
                      onChange={handleInputChange}
                      placeholder="Confirm password"
                      required
                    />
                  </div>
                </div>

                <div className="text-end">
                  <button type="submit" className="btn btn-soft-success">
                    <i className="ri-save-line align-middle me-1"></i>
                    Change Password
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Wrapper>
  );
};

export default ChangePassword;
