import axios from "axios";

export async function ShowOverviewData({ from, to }) {
  try {
    const response = await axios.get(`/v1/backoffice/ib/overview`, {
      params: {
        from,
        to,
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching overview data:", error);
    throw error;
  }
}
