import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import Breadcrump from '../../../components/Common/BreadCrumb.js'
import { ListWallet } from '../../../api/wallet.jsx'
import { ToastContainer } from "react-toastify";
import WithdrawnForm from "./withdrawn-form.jsx";

const PaymentgatewayManagement = () => {
    const [wallets, setWallets] = useState([]),
        [loading, setLoading] = useState(false)

    async function getWallets() {
        setLoading(true)
        try {
            const result = await ListWallet()
            setWallets(result)
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    useEffect(() => {
        getWallets()
    }, [])

    return (
        <React.Fragment>
            <Helmet>
                <title>Bank Transfer Management - Zexter IB Dashboard</title>
            </Helmet>
            <div className="page-content">
                <ToastContainer autoClose={3000} />
                <Container fluid>
                    <Breadcrump title={"Withdrawal"} />
                    <WithdrawnForm />
                </Container>
            </div>
        </React.Fragment >
    );
};

export default PaymentgatewayManagement;
