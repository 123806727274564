import { Link } from 'react-router-dom';
import commanumber from 'comma-number';
import { copyClipboard } from '../../utils/clipboard.js';
import Moment from 'react-moment'


const positionRowList = ({
    id,
    amount,
    symbol,
    lot,
    order_type,
    spread,
    commission,
    created_at
}) => {

    return (
        <tr>
            <th scope="row">
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="chk_child"
                        value="option1"
                    />
                </div>
            </th>
            <td
                className="id"
                style={{ display: "none" }}
            >
                <Link
                    to="#"
                    className="fw-medium link-primary"
                >
                    #
                </Link>
            </td>
            <td className="customer_name cursor-pointer">
                <i
                    onClick={copyClipboard.bind(this, id)}
                    style={{ cursor: "pointer" }}
                    className="ri-file-copy-line"
                ></i>
                {id}
            </td>
            <td className="px-4 text-success">{symbol}</td>
            <td className="px-4">{commanumber(Number(lot).toFixed(2))}</td>
            {order_type === 0 ? <td className="px-4 text-success">Buy</td> : <td className="px-4 text-danger">Sell</td>}
            <td className="px-4">{commanumber(Number(spread).toFixed(2))}</td>
            <td className="px-4">{commanumber(Number(commission).toFixed(2))}</td>
            <td className="px-4 text-success">{commanumber(Number(amount).toFixed(2))}</td>
            <td className="px-4">{<Moment calendar date={created_at} />}</td>
        </tr>
    )
}

export default positionRowList;