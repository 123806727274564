import React from "react";
import { Button, ButtonGroup, Col, Input, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";

const Section = ({ currency, setCurrency, date, handleDateChange }) => {
  const today = new Date();

  const tomorrow = new Date(date);
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              <h4 className="fs-16 mb-1">Good Day !</h4>
              <p className="text-muted mb-0">
                Here's what's happening with in the platform today.
              </p>
            </div>
            <ButtonGroup className="mt-3 mt-lg-0 mx-lg-3">
              <Input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio1"
                defaultChecked=""
              />
              <Button
                onClick={() => setCurrency(!currency)}
                color="success"
                className="mb-0 btn-md"
                id="btnradio1"
                outline={currency}
              >
                {" "}
                USD{" "}
              </Button>

              <Input
                type="radio"
                className="btn-check"
                name="btnradio"
                id="btnradio2"
              />
              <Button
                onClick={() => setCurrency(!currency)}
                color="success"
                className="mb-0 btn-md"
                id="btnradio2"
                outline={!currency}
              >
                THB
              </Button>
            </ButtonGroup>
            <div className="mt-3 mt-lg-0">
              <form action="#">
                <Row className="g-3 mb-0 align-items-center">
                  <div className="col-sm-auto">
                    <div className="input-group">
                  
                      <Flatpickr
                        className="form-control border-0 dash-filter-picker shadow"
                        options={{
                          mode: "range",
                          dateFormat: "Z ",
                          defaultDate: [today, tomorrow],
                          altFormat: "d M Y",
                          altInput: true,
                        }}
                        onChange={handleDateChange}
                      />
                      <div className="input-group-text bg-primary border-primary text-white">
                        <i className="ri-calendar-2-line"></i>
                      </div>
                    </div>
                  </div>
                </Row>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Section;
