import React from 'react'
import { Card, CardBody, Label, Col, Input, Row } from 'reactstrap'
import { getSubIBWithFilter } from '../../api/subib'
import { useState } from 'react'
import { DEFAULT_LIMIT } from '../../constant/pagination'

export default function SubIBFilter({ setTraders, page, toast, setTotalPage, setLoading }) {
    const [id, setID] = useState("")
    const [name, setName] = useState("")

    async function fetchSubIBFilter(e) {
        e.preventDefault()
        setLoading(true)
        try {
            const result = await getSubIBWithFilter(DEFAULT_LIMIT, page, id, name)
            setTraders(result.data)
            setTotalPage(result.total_page)
            setLoading(false)
        } catch (error) {
            console.log(error);
            toast(error, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' })
        }
    }

    return (
        <Card>
            <CardBody>
                <form onSubmit={fetchSubIBFilter}>
                    <Row>
                        <Col lg={6} md={6}>
                            <div className="mb-3">
                                <Label htmlFor="choices-multiple-groups" className="form-label text-muted">
                                    Sub IB ID
                                </Label>
                                <Input value={id} onChange={(e) => setID(e.target.value)} type="text" className="form-control" id="placeholderInput" placeholder="Placeholder" />
                            </div>
                        </Col>
                        <Col lg={6} md={6}>
                            <div className="mb-3">
                                <Label htmlFor="choices-multiple-groups" className="form-label text-muted">
                                    Name
                                </Label>
                                <Input value={name} onChange={(e) => setName(e.target.value)} type="text" className="form-control" id="placeholderInput" placeholder="Placeholder" />
                            </div>
                        </Col>
                        <Col lg={12} className='text-end'>
                            <button type='submit' className='btn btn-success'>Search</button>
                        </Col>
                    </Row>
                </form>
            </CardBody>
        </Card>
    )
}
