import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { RevenueCharts } from "./DashboardOverviewCharts";
import CountUp from "react-countup";
import { ShowProfitchart } from "../../store/overview/profit_chart/action";

// กำหนดค่าปุ่มสำหรับเลือกช่วงเวลา
const timeframeButtons = [
  { type: "all", label: "All" },
  { type: "month", label: "1M", apiValue: "1m" },
  { type: "halfyear", label: "6M", apiValue: "6m" },
  { type: "year", label: "1Y", apiValue: "1y" },
];

const Revenue = () => {
  // State declarations
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [chartData, setChartData] = useState({});
  const [type, setType] = useState("month");
  // ดึงข้อมูลจาก API
  const fetchChartData = async (selectedType) => {
    try {
      setLoading(true);
      setError(null);
      const button = timeframeButtons.find((btn) => btn.type === selectedType);
      const time_frame = button?.apiValue || selectedType;
      const result = await ShowProfitchart({ time_frame });
      if (result) {
        setChartData(result);
      }
    } catch (err) {
      console.error("Error fetching chart data:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChartData(type);
  }, [type]);
  const onChangeChartPeriod = (pType) => {
    setType(pType);
    fetchChartData(pType);
  };

  // แสดง loading state

  // แสดง error state

  return (
    <React.Fragment>
      <Card>
        {/* Chart Header with Timeframe Buttons */}
        <CardHeader className="border-0 align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Lot Trade</h4>
          <div className="d-flex gap-1">
            {timeframeButtons.map(({ type: btnType, label }) => (
              <button
                key={btnType}
                type="button"
                className={`btn ${
                  type === btnType ? "btn-secondary" : "btn-soft-secondary"
                } btn-sm`}
                onClick={() => onChangeChartPeriod(btnType)}
              >
                {label}
              </button>
            ))}
          </div>
        </CardHeader>

        {/* Summary Statistics */}
        <CardHeader className="p-0 border-0 bg-soft-light">
          <Row className="g-0 text-center">
            <Col xs={6} sm={3}>
              <div className="p-3 border border-dashed border-start-0">
                <h5 className="mb-1">
                  <CountUp
                    start={0}
                    end={chartData.Headers?.Total_Positions || 0}
                    duration={1}
                    separator=","
                  />
                </h5>
                <p className="text-muted mb-0">Positions</p>
              </div>
            </Col>
            <Col xs={6} sm={3}>
              <div className="p-3 border border-dashed border-start-0">
                <h5 className="mb-1">
                  <CountUp
                    start={0}
                    end={chartData.Headers?.Total_Lots || 0}
                    duration={1}
                    separator=","
                  />
                </h5>
                <p className="text-muted mb-0">Lots</p>
              </div>
            </Col>
            <Col xs={6} sm={3}>
              <div className="p-3 border border-dashed border-start-0">
                <h5 className="mb-1">
                  <CountUp
                    start={0}
                    end={chartData.Headers?.Total_Users || 0}
                    duration={1}
                  />
                </h5>
                <p className="text-muted mb-0">Users</p>
              </div>
            </Col>
            <Col xs={6} sm={3}>
              <div className="p-3 border border-dashed border-start-0 border-end-0">
                <h5 className="mb-1">
                  <CountUp
                    prefix="฿"
                    start={0}
                    end={chartData.Headers?.Total_Commissions || 0}
                    decimals={2}
                    duration={1}
                  />
                </h5>
                <p className="text-muted mb-0">Commissions</p>
              </div>
            </Col>
          </Row>
        </CardHeader>

        {/* Chart */}
        {loading ? (
          <div className="text-center p-4  ">Loading...</div>
        ) : (
          <CardBody className="p-0 pb-2">
            <div className="w-100">
              <div dir="ltr">
                <RevenueCharts
                  series={chartData.Series}
                  dataColors='["--vz-primary", "--vz-success", "--vz-danger", "--vz-info"]'
                />
              </div>
            </div>
          </CardBody>
        )}
      </Card>
    </React.Fragment>
  );
};

export default Revenue;
