import axios from "axios";

//Withdrawn
export const WithdrawnComission = async (body) => {
    try {
        const result = await axios.post(`/v1/payment/ib/smilepayz/withdrawn`, body);
        return result;
    }
    catch (error) {
        return Promise.reject(error);
    }
}

//List Position
export const listTransaction = async (limit, page) => {
    try {
        const result = await axios.get(`/v1/payment/ib/list?limit=${limit}&page=${page}`);
        return result;
    }
    catch (error) {
        return Promise.reject(error);
    }
}