import {
  ToastContainer
} from 'react-toastify';
import {
  Card,
  CardHeader,
  CardBody
} from 'reactstrap';
import Pagination from '../Common/Pagination';
import RebateRowList from '../List/rebateRowList';


const headers = [
  'ID',
  'Symbol',
  'Lot',
  'Order Type',
  'Spread',
  'Commission',
  'Rebate',
  "Created At",
];

const TablePosition = ({
  page,
  totalPage,
  positions,
  onSetPage,
}) => {
  console.log(positions);

  return (
    <Card>
      <CardHeader>
        <h4 className="card-title mb-0">Position List</h4>
      </CardHeader>
      <ToastContainer autoClose={1000} />
      <CardBody>
        <div id="customerList">
          <div className="table-responsive table-card mb-1">
            <table className="table align-middle table-nowrap" id="customerTable">
              <thead className="table-light">
                <tr>
                  <th
                    scope="col"
                    style={{ width: "50px" }}
                  >
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="checkAll"
                        value="option"
                      />
                    </div>
                  </th>
                  {
                    headers.map((header) => (
                      <th
                        key={header}
                        className='sort px-4'
                      >
                        {header}
                      </th>
                    ))
                  }
                </tr>
              </thead>
              <tbody className="list form-check-all">
                {
                  positions.map((item) => (
                    <RebateRowList
                      key={item.id}
                      id={item.id}
                      amount={item.amount}
                      symbol={item.symbol}
                      lot={item.lot}
                      order_type={item.order_type}
                      spread={item.spread}
                      commission={item.commission}
                      created_at={item.created_at.Time}

                    />
                  ))
                }
              </tbody>
            </table>
            <div
              className="noresult"
              style={{ display: "none" }}
            >
              <div className="text-center">
                <lord-icon
                  src="https://cdn.lordicon.com/msoeawqm.json"
                  trigger="loop"
                  colors="primary:#121331,secondary:#08a88a"
                  style={{ width: "75px", height: "75px" }}
                />
                <h5 className="mt-2">Sorry! No Result Found</h5>
                <p className="text-muted mb-0">
                  We've searched more than 150+ Orders We did not find anyorders for you search.
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="flex-shrink-0 mt-1">
              <div className="text-muted">
                Showing <span className="fw-semibold">{page}</span> of <span className="fw-semibold">{totalPage}</span> Results
              </div>
            </div>
            <div className="pagination-wrap hstack gap-2">
              <Pagination
                setPage={onSetPage}
                initial_page={page}
                total_page={totalPage}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default TablePosition;