import React, { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch } from "react-redux";
import { login_ib, loginUser, logout_ib, logoutUser } from "../store/actions";
import { jwtDecode } from "jwt-decode";



const AuthProtected = (props) => {
  const dispatch = useDispatch();
  // const { userProfile, loading, token } = useProfile();
  const token = localStorage.getItem("jwt")

  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        // Check if the token is expired
        if (decoded.expire_at < currentTime) {
          dispatch(logout_ib());
          window.location = "/login"
        } else {
          setAuthorization(token);
          dispatch(login_ib(decoded));
        }
      } catch (error) {
        // Handle the error appropriately
        console.error('Invalid token:', error);
        dispatch(logout_ib());
        window.location = "/login"
      }
    } else {
      dispatch(logout_ib());
      window.location = "/login"
    }
  }, [token]);
  /*
    redirect is un-auth access protected routes via url
  */

  if (!token) {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    );
  }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };