import React, { useState } from "react";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

import Wrapper from "../../../components/Common/Wrapper";
import OverviewTab from "./OverviewTab";
import PositionTap from "./PositionTap";
import UserDetailTab from "./UserTab";
import PasswordTap from "./PasswordTab";

const UserDetail = () => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const [userDetails, setUserDetails] = useState({
    traderId: "TD123456",
    email: "trader@example.com",
    phone: "+1234567890",
    cashBalance: 50000.0,
    status: "active",
    preferredLanguage: "en",
    preferredCurrency: "USD",
    createdAt: "2024-01-15",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [isEditing, setIsEditing] = useState(false);

  const handleInputChange = (e) => {
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsEditing(false);
    // Add your API call here to update user details
    console.log("Updated user details:", userDetails);
  };

  return (
    <Wrapper>
      <div className="text-muted p-4">
        <Nav tabs className="border-0 mb-4">
          <NavItem>
            <NavLink
              className={classnames("border-0 px-4 cursor-pointer", {
                active: activeTab === "1",
                "text-secondary border-bottom border-secondary ":
                  activeTab === "1",
                "": activeTab !== "1",
              })}
              onClick={() => toggle("1")}
            >
              Overview
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames("border-0 px-4 cursor-pointer", {
                active: activeTab === "2",
                "text-secondary border-bottom border-secondary cursor-pointer":
                  activeTab === "2",
                "": activeTab !== "2",
              })}
              onClick={() => toggle("2")}
            >
              Position History
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames("border-0 px-4 cursor-pointer", {
                active: activeTab === "3",
                "text-secondary border-bottom border-secondary cursor-pointer":
                  activeTab === "3",
                "": activeTab !== "3",
              })}
              onClick={() => toggle("3")}
            >
              Users Setting
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={classnames("border-0 px-4 cursor-pointer", {
                active: activeTab === "4",
                "text-secondary border-bottom border-secondary cursor-pointer":
                  activeTab === "4",
                "": activeTab !== "4",
              })}
              onClick={() => toggle("4")}
            >
              Reset Password
            </NavLink>
          </NavItem> */}
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <OverviewTab />
          </TabPane>

          <TabPane tabId="2">
            <PositionTap />
          </TabPane>
          <TabPane tabId="3">
            <UserDetailTab />
          </TabPane>
          <TabPane tabId="4">
            <PasswordTap />
          </TabPane>
        </TabContent>
      </div>
    </Wrapper>
  );
};

export default UserDetail;
