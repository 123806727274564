import axios from 'axios';


export const getIBDetailById = async (id) => {
  try {
    const result = await axios.get(`/v1/ib/detail`);
    return result;
  }
  catch (error) {
    return Promise.reject(error);
  }
}
