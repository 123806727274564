import axios from 'axios';

export async function createMarketingChannel(data) {
  try {
    const result = await axios.post('/v1/marketing-channel/ib', data);
    return result;
  }
  catch (error) {
    return Promise.reject(error);
  }
}

export async function listMarketingChannel(liimt, page) {
  try {
    const result = await axios.get(`/v1/marketing-channel/ib/list?limit=${liimt}&page=${page}`);
    return result;
  }
  catch (error) {
    return Promise.reject(error);
  }
}

export async function listMarketingChannelFilter(liimt, page, id, name, platform) {
  try {
    const result = await axios.get(`/v1/marketing-channel/ib/list/filter?limit=${liimt}&page=${page}&name=${name}&platform=${platform}&id=${id}`);
    return result;
  }
  catch (error) {
    return Promise.reject(error);
  }
}