import React from 'react';

export default function Pagination({ setPage, initial_page, total_page }) {
    const currentPage = initial_page;
    const pages = [];

    // Generate page numbers
    for (let i = 1; i <= total_page; i++) {
        pages.push(i);
    }

    // Calculate the range of pages to display
    let startPage, endPage;
    if (total_page <= 3) {
        startPage = 1;
        endPage = total_page;
    } else {
        if (currentPage <= 2) {
            startPage = 1;
            endPage = 3;
        } else if (currentPage >= total_page - 1) {
            startPage = total_page - 2;
            endPage = total_page;
        } else {
            startPage = currentPage - 1;
            endPage = currentPage + 1;
        }
    }

    return (
        <ul className="pagination listjs-pagination mb-0">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button
                    onClick={() => setPage(currentPage - 1)}
                    className="page-link"
                    disabled={currentPage === 1}
                >
                    ←
                </button>
            </li>
            {startPage > 1 && (
                <>
                    <li className="page-item">
                        <button onClick={() => setPage(1)} className="page-link">1</button>
                    </li>
                    {startPage > 2 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                </>
            )}
            {pages.slice(startPage - 1, endPage).map(page => (
                <li key={page} className={`page-item ${page === currentPage ? 'active' : ''}`}>
                    <button onClick={() => setPage(page)} className="page-link">{page}</button>
                </li>
            ))}
            {endPage < total_page && (
                <>
                    {endPage < total_page - 1 && <li className="page-item disabled"><span className="page-link">...</span></li>}
                    <li className="page-item">
                        <button onClick={() => setPage(total_page)} className="page-link">{total_page}</button>
                    </li>
                </>
            )}
            <li className={`page-item ${currentPage === total_page ? 'disabled' : ''}`}>
                <button
                    onClick={() => setPage(currentPage + 1)}
                    className="page-link"
                    disabled={currentPage === total_page}
                >
                    →
                </button>
            </li>
        </ul>
    );
}
