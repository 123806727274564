import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  TabContent,
  TabPane,
} from "reactstrap";
import CountUp from "react-countup";
import { UserOverview } from "../../../store/trader/useroverview/action";

const OverviewTab = () => {
  const { id } = useParams();
  const [user, setUser] = useState({});

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await UserOverview(id);
        setUser(result);
      } catch (error) {
        console.error("Error fetching data:", error);
        setUser({
          TotalLotTrade: 0,
          TotalCommission: 0,
          TotalPosition: 0,
          CurrentPosition: {
            Buy: null,
            Sell: null,
          },
        });
      }
    }
    fetchData();
  }, [id]);
  const cardData = [
    {
      label: "TOTAL LOT",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      percentage: "0.00",
      prefix: "",
      suffix: "",
      separator: ",",
      counter: user ? user.TotalLotTrade : 0,
      decimals: 0,
      link: "View Trading",
      bgcolor: "success",
      icon: "ri-wallet-2-line",
    },
    {
      label: "COMMISSION",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      percentage: "0.00",
      prefix: "$ ",
      suffix: "",
      separator: ",",
      counter: user ? user.TotalCommission : 0,
      decimals: 2,
      link: "View Transaction",
      bgcolor: "primary",
      icon: "ri-time-line",
    },
    {
      label: "TOTAL  Position",
      badge: "ri-arrow-right-down-line",
      badgeClass: "danger",
      percentage: "0.00",
      prefix: "",
      suffix: "",
      separator: ",",
      counter: user ? user.TotalPosition : 0,
      decimals: 2,
      link: "View Details",
      bgcolor: "info",
      icon: "ri-user-3-line",
    },
  ];

  const renderPositionTable = (positions, type) => {
    return (
      <Card className="border-secondary">
        <CardBody>
          <h5 className={`text-${type === "Buy" ? "success" : "danger"} mb-4`}>
            Current {type} Position
          </h5>
          <div className="table-responsive">
            <table className="table table-dark table-borderless mb-0">
              <thead>
                <tr>
                  <th>Symbol</th>
                  <th>Lot</th>
                  <th>Open Price</th>
                  <th>Spread</th>
                  <th>Commission</th>
                  <th>Rebate</th>
                </tr>
              </thead>
              <tbody>
                {!positions ? (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No data available
                    </td>
                  </tr>
                ) : (
                  positions.map((position, index) => (
                    <tr key={index}>
                      <td>{position.symbol}</td>
                      <td
                        className={`text-${
                          type === "Buy" ? "success" : "danger"
                        }`}
                      >
                        {position.lot}
                      </td>
                      <td
                        className={`text-${
                          type === "Buy" ? "success" : "danger"
                        }`}
                      >
                        {position.open_price}
                      </td>
                      <td
                        className={`text-${
                          type === "Buy" ? "success" : "danger"
                        }`}
                      >
                        {position.spread}
                      </td>
                      <td>{position.commission}</td>
                      <td
                        className={`text-${
                          type === "Buy" ? "success" : "danger"
                        }`}
                      >
                        +{position.rebate}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    );
  };
  return (
    <>
      <Row className="mb-4">
        {cardData.map((item, index) => (
          <Col md={4} key={index}>
            <Card className="card-animate">
              <CardBody>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                      {item.label}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-4">
                  <div>
                    <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                      <span
                        className="counter-value"
                        data-target={item.counter}
                      >
                        <CountUp
                          start={0}
                          prefix={item.prefix}
                          suffix={item.suffix}
                          separator={item.separator}
                          end={item.counter}
                          decimals={item.decimals}
                          duration={1}
                        />
                      </span>
                    </h4>
                  </div>
                  <div className="avatar-sm flex-shrink-0">
                    <span
                      className={`avatar-title rounded fs-3 bg-soft-${item.bgcolor}`}
                    >
                      <i className={`text-${item.bgcolor} ${item.icon}`}></i>
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      <Row>
        <Col md={6}>
          {user &&
            user.CurrentPosition &&
            renderPositionTable(user.CurrentPosition.Buy, "Buy")}
        </Col>
        <Col md={6}>
          {user &&
            user.CurrentPosition &&
            renderPositionTable(user.CurrentPosition.Sell, "Sell")}
        </Col>
      </Row>
    </>
  );
};

export default OverviewTab;
