import React from "react";
import { Link } from "react-router-dom";
import { Card, CardHeader, CardBody } from "reactstrap";
import commanumber from "comma-number";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "../Common/Pagination";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";

function PaginatedTable({ page, setPage, total_page, traders }) {
  const navigate = useNavigate();

  const handleClick = ({ id }) => {
    navigate(`/trader/balance/adjust/${id}`);
  };
  async function copyClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
      toast("Copied !", {
        position: "top-center",
        hideProgressBar: true,
        className: "bg-success text-white",
      });
    } catch (error) {
      console.log(error);
    }
  }

  let render_traders = traders.map((item) => (
    <tr key={item.id}>
      <th scope="row">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name="chk_child"
            value="option1"
          />
        </div>
      </th>
      <td className="id" style={{ display: "none" }}>
        <Link to="#" className="fw-medium link-primary">
          #
        </Link>
      </td>
      <td className="customer_name">
        <i
          onClick={() => copyClipboard(item.id)}
          style={{ cursor: "pointer" }}
          className="ri-file-copy-line"
        ></i>
        <Link to={`/admin/${item.id} `} className="">
          {item.id}
        </Link>
      </td>
      <td className="email">
        <Link to={`/admin/${item.id} `} className="">
          {item.username}
        </Link>
      </td>
      <td className="role">
        <span className={"badge badge-soft-" + "success" + " p-2"}>Admin</span>
      </td>
      <td className="date">
        <Moment calendar date={item.created_at} />
      </td>
    </tr>
  ));

  return (
    <Card>
      <CardHeader>
        <h4 className="card-title mb-0">Trader List</h4>
      </CardHeader>
      <ToastContainer autoClose={1000} />
      <CardBody>
        <div id="customerList">
          <div className="table-responsive table-card mb-1">
            <table
              className="table align-middle table-nowrap"
              id="customerTable"
            >
              <thead className="table-light">
                <tr>
                  <th scope="col" style={{ width: "50px" }}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="checkAll"
                        value="option"
                      />
                    </div>
                  </th>
                  <th className="sort" data-sort="customer_name">
                    Admin ID
                  </th>
                  <th className="sort" data-sort="email">
                    Email
                  </th>
                  <th className="sort" data-sort="action">
                    Role
                  </th>
                  <th className="sort" data-sort="action">
                    Created At
                  </th>
                </tr>
              </thead>
              <tbody className="list form-check-all">{render_traders}</tbody>
            </table>
            <div className="noresult" style={{ display: "none" }}>
              <div className="text-center">
                <lord-icon
                  src="https://cdn.lordicon.com/msoeawqm.json"
                  trigger="loop"
                  colors="primary:#121331,secondary:#08a88a"
                  style={{ width: "75px", height: "75px" }}
                ></lord-icon>
                <h5 className="mt-2">Sorry! No Result Found</h5>
                <p className="text-muted mb-0">
                  We've searched more than 150+ Orders We did not find any
                  orders for you search.
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <div className="flex-shrink-0 mt-1">
              <div className="text-muted">
                Showing <span className="fw-semibold">{page}</span> of{" "}
                <span className="fw-semibold">{total_page}</span> Results
              </div>
            </div>
            <div className="pagination-wrap hstack gap-2">
              <Pagination
                setPage={setPage}
                initial_page={page}
                total_page={total_page}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default PaginatedTable;
