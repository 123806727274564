import React from "react";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";
import { isEmpty } from '../../utils/isEmpty'

const RevenueCharts = ({ dataColors, series }) => {
  console.log("series:", series);

  // แปลงข้อมูลให้อยู่ในรูปแบบที่ ApexCharts ต้องการ
  const transformedSeries = [
    {
      name: "Positions",
      type: "area",
      data: Object.values(series?.Positions || {}),
    },
    {
      name: "Lots",
      type: "bar",
      data: Object.values(series?.Lots || {}),
    },
    {
      name: "Users",
      type: "line",
      data: Object.values(series?.Users || {}),
    },
    {
      name: "Commissions",
      type: "line",
      data: Object.values(series?.Commissions || {}),
    },
  ];

  // สร้าง categories จาก keys ของข้อมูล
  const categories = Object.keys(series?.Positions || {}).map((date) => {
    const [year, month] = date.split("-");
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${monthNames[parseInt(month) - 1]} ${year.slice(-2)}`;
  });

  var linechartcustomerColors = getChartColorsArray(dataColors);

  var options = {
    chart: {
      height: 370,
      type: "line",
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "straight",
      dashArray: [0, 0, 8, 8], // solid line for Positions & Lots, dashed for Users & Commissions
      width: [2, 0, 2.2, 2.2],
    },
    fill: {
      opacity: [0.1, 0.9, 1, 1],
    },
    markers: {
      size: [0, 0, 0, 0],
      strokeWidth: 2,
      hover: {
        size: 4,
      },
    },
    xaxis: {
      categories: categories,
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      labels: {
        rotate: -45,
        style: {
          fontSize: "12px",
        },
      },
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      padding: {
        top: 0,
        right: -2,
        bottom: 15,
        left: 10,
      },
    },
    legend: {
      show: true,
      horizontalAlign: "center",
      offsetX: 0,
      offsetY: -5,
      markers: {
        width: 9,
        height: 9,
        radius: 6,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
        barHeight: "70%",
      },
    },
    colors: linechartcustomerColors,
    tooltip: {
      shared: true,
      y: [
        {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0); // Positions (จำนวนเต็ม)
            }
            return y;
          },
        },
        {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(2); // Lots (ทศนิยม 2 ตำแหน่ง)
            }
            return y;
          },
        },
        {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0); // Users (จำนวนเต็ม)
            }
            return y;
          },
        },
        {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return `฿${y.toFixed(2)}`; // Commissions (เพิ่มสัญลักษณ์เงินบาท)
            }
            return y;
          },
        },
      ],
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={transformedSeries}
        type="line"
        height="370"
        className="apex-charts"
      />
    </React.Fragment>
  );
};

const StoreVisitsCharts = ({ dataColors, labels, series }) => {
  var chartDonutBasicColors = getChartColorsArray(dataColors);
  var options = {
    labels: !isEmpty(labels) ? labels : ["Channel"],
    chart: {
      height: 333,
      type: "donut",
    },
    legend: {
      position: "bottom",
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
    },
    colors: chartDonutBasicColors,
  };
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={!isEmpty(series) ? series : [0]}
        type="donut"
        height="333"
        className="apex-charts"
      />
    </React.Fragment>
  );
};
export { RevenueCharts, StoreVisitsCharts };
