import React, { useState, useEffect } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";
import { UserDetail } from "../../../store/trader/userdetail/action";
const UserDetailTab = () => {
  const [userDetails, setUserDetails] = useState({});
  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await UserDetail(id);
        setUserDetails(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, [id]);

  return (
    <Card className="border-secondary">
      <CardBody>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h5 className="mb-0">User Details</h5>
        </div>

        <Row>
          <Col md={6}>
            {/* User Details Section */}
            <div className="mb-4">
              <h6 className="text-muted mb-3">Account Information</h6>

              <div className="mb-3">
                <label className="form-label">Trader ID</label>
                <input
                  type="text"
                  className="form-control bg-dark text-muted"
                  value={userDetails.id}
                  disabled
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  name="email"
                  className="form-control bg-dark text-muted"
                  value={userDetails.email}
                  disabled
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Phone</label>
                <input
                  type="tel"
                  name="phone"
                  className="form-control bg-dark text-muted"
                  value={userDetails.phone}
                  disabled
                />
              </div>

              <div className="mb-3">
                <label className="form-label">Cash Balance</label>
                <div className="input-group">
                  <span className="input-group-text bg-dark text-muted">$</span>
                  <input
                    type="text"
                    className="form-control bg-dark text-muted"
                    value={userDetails.cash_balance}
                    disabled
                  />
                </div>
              </div>
            </div>
          </Col>

          <Col md={6}>
            {/* Preferences & Password Section */}
            <div className="mb-3">
              <h6 className="text-muted mb-3">Preferences</h6>

              <div className="mb-3">
                <label className="form-label">Preferred Language</label>
                <input
                  name="preferredLanguage"
                  className="form-select bg-dark text-muted"
                  value={userDetails.preferred_lang}
                  disabled
                ></input>
              </div>

              <div className="mb-3">
                <label className="form-label">Preferred Currency</label>
                <input
                  name="preferredCurrency"
                  className="form-select bg-dark text-muted"
                  value={userDetails.preferred_currency}
                  disabled
                />
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Platform</label>
              <input
                name="platform"
                className="form-select bg-dark text-muted"
                value={userDetails.platform}
                disabled
              ></input>
            </div>

            <div className="mb-3">
              <label className="form-label">Created At</label>
              <input
                type="text"
                className="form-control bg-dark text-muted"
                value={new Date(userDetails.created_at).toLocaleDateString()}
                disabled
              />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default UserDetailTab;
