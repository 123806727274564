import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Label, Input, Card, CardBody, Button, Spinner, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Helmet } from "react-helmet";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import BreadCrumb from '../../../components/Common/BreadCrumb'
import { createSubIB } from "../../../api/subib";
import { getIBDetailById } from "../../../api/IB";
import { onChangeAllowNumberOnly } from "../../../utils/validateNumber";
import { Link } from 'react-router-dom';

const CreateSubIB = () => {

    const [name, setName] = useState(""),
        [url, setUrl] = useState(""),
        [email, setEmail] = useState(""),
        [phone, setPhone] = useState(""),
        [share, setShare] = useState(""),
        [ib_share, setIBShare] = useState(""),
        [username, setUsername] = useState(""),
        [password, setPassword] = useState(""),
        [loading, setLoading] = useState(false)


    const navigate = useNavigate()

    async function onSubmitAddWallet(e) {
        e.preventDefault()
        // tog_center()
        setLoading(true)
        try {
            const body = {
                name,
                url,
                email,
                phone,
                share
            }
            const data = await createSubIB(body)
            setUsername(data.email)
            setPassword(data.password)
            tog_center()
            // navigate('/sub', { state: { toastMessage: "Channel Created Successfully!" } });
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast(error, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' })
        }
    }

    async function getIBDetail() {
        try {
            const data = await getIBDetailById()
            setIBShare(data.share)
        } catch (error) {
            toast(error, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' })
        }
    }

    useEffect(() => {
        getIBDetail()
    }, [])


    const [modal_center, setmodal_center] = useState(false);

    function tog_center() {
        setmodal_center(!modal_center);
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Create Sub IB - Zexter Admin Dashboard</title>
            </Helmet>
            <ToastContainer autoClose={5000} />

            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={"Create Sub IB"} pageTitle={"Sub IB"} link={"/sub"} />
                    <Modal
                        isOpen={modal_center}
                        toggle={() => {
                            tog_center();
                        }}
                        centered
                    >
                        <ModalHeader className="modal-title" />

                        <ModalBody className="text-center p-5">
                            <lord-icon src="https://cdn.lordicon.com/hrqwmuhr.json"
                                trigger="loop" colors="primary:#121331,secondary:#08a88a" style={{ width: "120px", height: "120px" }}>
                            </lord-icon>
                            <div className="mt-4">
                                <h4 className="mb-3">Successfully Create Sub IB !</h4>
                                <p className="text-muted mb-4">Username and Password For Sub IB.</p>
                                <div className="d-flex justify-content-center gap-2">
                                    <h5 className="text-success">Username:</h5>
                                    <h5>{username}</h5>
                                </div>
                                <div className="d-flex justify-content-center gap-2">
                                    <h5 className="text-success">Password:</h5>
                                    <h5>{password}</h5>
                                </div>
                                {/* <h5>Username: test@gmail.com</h5>
                                <h5>Password: test@gmail.com</h5> */}
                                <div className="hstack gap-2 justify-content-center mt-4">
                                    <Button color="light" onClick={() => setmodal_center(false)}>Close</Button>
                                    <Link to="/sub" className="btn btn-success">Go To Sub IB List</Link>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>

                    <Card className="col-lg-6 col-md-12">
                        <CardBody>
                            <Form onSubmit={onSubmitAddWallet}>
                                <Row>
                                    <Col md={12}>
                                        <div className="d-flex gap-2">
                                            <h5>Your Percentage:</h5>
                                            <h5 className="text-success">{ib_share} %</h5>
                                        </div>
                                        <span>The given percentage for Sub IB must lower or equal to IB percentage</span>
                                    </Col>
                                    <Col className={"mt-2"} md={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="firstNameinput" className="form-label">Name</Label>
                                            <Input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Enter Sub IB Name" id="firstNameinput" />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="choices-single-no-sorting" className="form-label">
                                                Url
                                            </Label>
                                            <Input type="text" value={url} onChange={(e) => setUrl(e.target.value)} className="form-control" placeholder="Enter Url" id="firstNameinput" />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="choices-single-no-sorting" className="form-label">
                                                Email
                                            </Label>
                                            <Input type="text" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Enter Email" id="firstNameinput" />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="choices-single-no-sorting" className="form-label">
                                                Phone
                                            </Label>
                                            <Input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control" placeholder="Enter Phone" id="firstNameinput" />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="choices-single-no-sorting" className="form-label">
                                                Share
                                            </Label>
                                            <Input type="text" value={share} onChange={(e) => onChangeAllowNumberOnly(e.target.value, setShare)} className="form-control" placeholder="Enter Share" id="firstNameinput" />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        {loading ? (
                                            <div className="text-end">
                                                <Button color="primary" className="btn-load" outline>
                                                    <span className="d-flex align-items-center">
                                                        <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>
                                                        <span className="flex-grow-1 ms-2">
                                                            Loading...
                                                        </span>
                                                    </span>
                                                </Button>
                                            </div>
                                        ) : (
                                            <div className="text-end">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default CreateSubIB;
