import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
    const [showSubIB, setShowSubIB] = useState(true)
    const history = useNavigate();

    //Dashbaord
    const [isOverview, setIsOverview] = useState(false);
    const [isApps, setIsApps] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [isPages, setIsPages] = useState(false);
    const [isBaseUi, setIsBaseUi] = useState(false);
    //Trade
    const [isPosition, setIsPosition] = useState(false);
    const [isOrder, setIsOrder] = useState(false);
    //Group
    const [isTrader, setIsTrader] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    //Securities
    const [isForex, setIsForex] = useState(false);
    const [isCrypto, setIsCrypto] = useState(false);
    const [isMetal, setIsMetal] = useState(false);
    const [isCommodities, setIsCommodities] = useState(false);
    //Marketing
    const [isPromotion, setIsPromotion] = useState(false);
    const [isBonus, setIsBonus] = useState(false);
    const [isRebate, setIsRebate] = useState(false);
    //Payment
    const [isBank, setIsBank] = useState(false);
    const [isPaymentGateway, setIsPaymentGateway] = useState(false);
    const [isCryptocurrency, setIsCryptocurrency] = useState(false);
    const [isTransaction, setIsTransaction] = useState(false);
    //Trade
    const [isMarketingChannel, setMarketingChannel] = useState(false);
    //Business Partner
    const [isIB, setIsIB] = useState(false);
    const [isAffiliate, setIsAffiliate] = useState(false);
    const [isWhitelabel, setIsWhitelabel] = useState(false);
    const [isAPI, setIsAPI] = useState(false);


    const [isAdvanceUi, setIsAdvanceUi] = useState(false);
    const [isForms, setIsForms] = useState(false);
    const [isTables, setIsTables] = useState(false);
    const [isCharts, setIsCharts] = useState(false);
    const [isIcons, setIsIcons] = useState(false);
    const [isMaps, setIsMaps] = useState(false);
    const [isMultiLevel, setIsMultiLevel] = useState(false);

    // Authentication
    const [isSignIn, setIsSignIn] = useState(false);
    const [isSignUp, setIsSignUp] = useState(false);
    const [isPasswordReset, setIsPasswordReset] = useState(false);
    const [isPasswordCreate, setIsPasswordCreate] = useState(false);
    const [isLockScreen, setIsLockScreen] = useState(false);
    const [isLogout, setIsLogout] = useState(false);
    const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    const [isVerification, setIsVerification] = useState(false);
    const [isError, setIsError] = useState(false);

    // Pages
    const [isProfile, setIsProfile] = useState(false);
    const [isLanding, setIsLanding] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsOverview(false);
        }
        if (iscurrentState !== 'Apps') {
            setIsApps(false);
        }
        if (iscurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (iscurrentState !== 'Pages') {
            setIsPages(false);
        }
        if (iscurrentState !== 'BaseUi') {
            setIsBaseUi(false);
        }
        if (iscurrentState !== 'AdvanceUi') {
            setIsAdvanceUi(false);
        }
        if (iscurrentState !== 'Forms') {
            setIsForms(false);
        }
        if (iscurrentState !== 'Tables') {
            setIsTables(false);
        }
        if (iscurrentState !== 'Charts') {
            setIsCharts(false);
        }
        if (iscurrentState !== 'Icons') {
            setIsIcons(false);
        }
        if (iscurrentState !== 'Maps') {
            setIsMaps(false);
        }
        if (iscurrentState !== 'MuliLevel') {
            setIsMultiLevel(false);
        }
        if (iscurrentState === 'Widgets') {
            history("/widgets");
            document.body.classList.add('twocolumn-panel');
        }
        if (iscurrentState !== 'Landing') {
            setIsLanding(false);
        }
    }, [
        history,
        iscurrentState,
        isOverview,
        isApps,
        isAuth,
        isPages,
        isBaseUi,
        isAdvanceUi,
        isForms,
        isTables,
        isCharts,
        isIcons,
        isMaps,
        isMultiLevel
    ]);

    const menuItems = [
        {
            label: "Dashboard",
            isHeader: true,
        },
        {
            id: "overview",
            label: "Overview",
            icon: "ri-dashboard-2-line",
            link: "/",
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Overview');
            }
        },
        {
            label: "Payment",
            isHeader: true,
        },
        {
            id: "bank",
            label: "Bank",
            icon: "ri-coin-fill",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsPaymentGateway(!isPaymentGateway);
                setIscurrentState('Paymentgateway');
                updateIconSidebar(e);
            },
            stateVariables: isPaymentGateway,
            subItems: [
                { id: "pg_wallet", label: "Withdrawal", link: "/paymentgateway", parentId: "paymentgateway" },
                { id: "transfer_inout", label: "Transaction History", link: "/paymentgateway/transfer-inout", parentId: "paymentgateway" },
            ],
        },
        {
            label: "User",
            isHeader: true,
        },
        {
            id: "trader",
            label: "Trader",
            icon: "ri-user-3-line",
            link: "/trader",
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Overview');
            }
        },
        {
            id: "admin",
            label: "Admin",
            icon: "ri-admin-line",
            link: "/admin",
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Overview');
            }
        },
        {
            label: "Trade",
            isHeader: true,
        },
        {
            id: "position",
            label: "Position",
            icon: "ri-money-dollar-box-fill",
            link: "/position",
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Position');
            }
        },
        {
            label: "Tool",
            isHeader: true,
        },
        {
            id: "marketing-channel",
            label: "Marketing Channel",
            icon: "ri-store-2-line",
            link: "/marketing-channel",
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Marketing Channel');
            }
        },
        {
            label: "Business Partner",
            isHeader: true,
        },
        {
            id: "sub",
            label: "Sub IB",
            icon: "ri-team-line",
            link: "/sub",
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Sub IB');
            }
        },
    ];

    // if (showSubIB) {
    //     menuItems.push(
    //         {
    //             label: "Business Partner",
    //             isHeader: true,
    //         },
    //     )
    // }

    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;