import { Card, CardBody, Label, Col, Input, Row, Button } from "reactstrap";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";

const FilterPosition = ({ onSearch }) => {
  const initialValues = {
    id: "",
    email: "",
    symbol: "",
  };

  const formFilterInput = useFormik({
    initialValues,
    onSubmit: async (values) => {
      onSearch(values);
    },
  });

  const handleReset = () => {
    formFilterInput.resetForm();
    onSearch(initialValues);
  };

  // แก้ไขการจัดการ Enter key
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      formFilterInput.handleSubmit();
    }
  };

  return (
    <Card>
      <ToastContainer autoClose={1000} />
      <CardBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formFilterInput.handleSubmit();
          }}
        >
          <Row>
            <Col lg={4} md={6}>
              <div className="mb-3">
                <Label htmlFor="idInput" className="form-label text-muted">
                  ID
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="idInput"
                  placeholder="Enter ID"
                  name="id"
                  value={formFilterInput.values.id}
                  onChange={formFilterInput.handleChange}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </Col>
            {/* <Col lg={4} md={6}>
              <div className="mb-3">
                <Label htmlFor="emailInput" className="form-label text-muted">
                  Email
                </Label>
                <Input
                  type="email"
                  className="form-control"
                  id="emailInput"
                  placeholder="Enter Email"
                  name="email"
                  value={formFilterInput.values.email}
                  onChange={formFilterInput.handleChange}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </Col> */}
            <Col lg={4} md={6}>
              <div className="mb-3">
                <Label htmlFor="symbolInput" className="form-label text-muted">
                  Symbol
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="symbolInput"
                  placeholder="Enter Symbol"
                  name="symbol"
                  value={formFilterInput.values.symbol}
                  onChange={formFilterInput.handleChange}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </Col>
            <Col lg={4} md={6} className="text-end my-auto ">
              <div className="hstack gap-2  mt-2">
                <Button
                  className="w-50"
                  type="button"
                  color="danger"
                  outline
                  onClick={handleReset}
                >
                  <i className="ri-restart-line align-bottom me-1"></i>
                  Reset
                </Button>
                <Button className="w-50" type="submit" color="success">
                  <i className="ri-search-line align-bottom me-1"></i>
                  Search
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </CardBody>
    </Card>
  );
};

export default FilterPosition;
