import axios from "axios";

export async function UserDetail(user_id) {
  try {
    const response = await axios.get(`/v1/ib/trader/${user_id}`);
    return response;
  } catch (error) {
    console.error("Error fetching profit chart data:", error);
    throw error;
  }
}
