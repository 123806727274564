import React from "react";
import { Col, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { useNavigate } from "react-router-dom";

export default function NavTraderBalance() {
  const navigate = useNavigate();

  const handleDateSelect = (selectedDates, dateStr, instance) => {
    console.log("Selected Dates:", selectedDates);
    console.log("Formatted Date String:", dateStr);
  };

  // Calculate default dates
  const now = new Date();
  const oneMonthFromNow = new Date();
  oneMonthFromNow.setMonth(now.getMonth() + 1);

  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              <h4 className="fs-16 mb-1">Marketing Channel Management</h4>
            </div>
            <div className="mt-3 mt-lg-0">
              <form action="#">
                <Row className="g-3 mb-0 align-items-center">
                  <div className="col-sm-auto">
                    <div className="input-group">
                      {/* <Flatpickr
                        className="form-control border-0 dash-filter-picker shadow"
                        options={{
                          mode: "range",
                          dateFormat: "d M, Y",
                          defaultDate: [now, oneMonthFromNow],
                        }}
                        onChange={handleDateSelect} // Add the onSelect handler here
                      /> */}
                    </div>
                  </div>
                  <div className="col-auto">
                    <button
                      type="button"
                      onClick={() => navigate("/marketing-channel/create")}
                      className="btn btn-soft-success"
                    >
                      <i className="ri-add-circle-line align-middle me-1"></i>
                      Create Marketing Channel
                    </button>
                  </div>
                </Row>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}
