import React from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Placeholder } from "reactstrap";
import { toastRequest } from "../../utils/toastRequest";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

const HeaderWidget = ({ data, rate, currency }) => {
  const store = useSelector((state) => state.User);

  async function copyLink() {
    await navigator.clipboard.writeText(
      "https://exchange.zexters.io/register?ib=" + store.user.ib_id
    );
    await toastRequest("Copied !", "bg-soft-success text-center");
  }

  function openLink() {
    window.open("https://exchange.zexters.io/register?ib=" + store.user.ib_id, "_blank");
  }

  return (
    <React.Fragment>
      <ToastContainer autoClose={1000} />
      <Col xl={6} md={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  IB Link
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <div className="text-center">
                <h4 className="fs-15 fw-semibold ff-secondary mb-4">
                  {store.user ? (
                    <Link
                      to="#"
                      onClick={openLink}
                      className="text-decoration-underline"
                    >
                      https://exchange.zexters.io/register?ib={store.user.ib_id}
                    </Link>
                  ) : (
                    <Placeholder as={Card.Title} animation="glow">
                      <Placeholder xs={6} />
                    </Placeholder>
                  )}
                </h4>
              </div>
            </div>
            <div className="w-full text-center">
              <Button
                onClick={copyLink}
                color="success"
                className="btn-label btn-sm"
              >
                {" "}
                <i className="ri-clipboard-line label-icon align-middle fs-16 me-2"></i>
                Copy
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>

      <Col xl={6} md={6}>
        <Card className="card-animate">
          <CardBody>
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  Balance
                </p>
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                  <span className="counter-value" data-target="559.25">
                    <CountUp
                      start={0}
                      prefix={currency ? " ฿ " : " $ "}
                      suffix={currency ? " บาท" : ""}
                      separator={","}
                      end={data ? data.Total_Balance * rate : 0}
                      decimals={2}
                      duration={1}
                    />
                  </span>
                </h4>
                <Link to="/paymentgateway/transfer-inout" className="text-decoration-underline">
                  View Trasaction History
                </Link>
              </div>
              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title rounded fs-3 bg-soft-success"}>
                  <i className={`text-success bx bx-dollar-circle`}></i>
                </span>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default HeaderWidget;
