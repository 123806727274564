import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";

const RecentOpenedPositions = ({ data }) => {
  return (
    <React.Fragment>
      <Col xl={12}>
        <Card>
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">
              Recent Opened Positions
            </h4>
          </CardHeader>

          <CardBody>
            <div className="table-responsive table-card">
              <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col">Symbol</th>
                    <th scope="col">Type</th>
                    <th scope="col">Size</th>
                    <th scope="col">Open Price</th>
                    <th scope="col">Close Price</th>
                    <th scope="col">Spread</th>
                    <th scope="col">Margin</th>
                    <th scope="col">Leverage</th>
                    <th scope="col">Open At</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={item.id + "-" + index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div>
                              <h5 className="fs-14 my-1">{item.symbol}</h5>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span
                            className={`badge badge-soft-${
                              item.order_type === 0 ? "success" : "danger"
                            }`}
                          >
                            {item.order_type === 0 ? "Buy" : "Sell"}
                          </span>
                        </td>
                        <td>
                          <h5 className="fs-14 my-1 fw-normal">{item.size}</h5>
                        </td>
                        <td>
                          <h5 className="fs-14 my-1 fw-normal">
                            {item.open_price.toFixed(2)}
                          </h5>
                        </td>
                        <td>
                          <h5 className="fs-14 my-1 fw-normal">
                            {item.close_price.Valid
                              ? item.close_price.Float64.toFixed(2)
                              : "-"}
                          </h5>
                        </td>
                        <td>
                          <h5 className="fs-14 my-1 fw-normal">
                            {item.spread}
                          </h5>
                        </td>
                        <td>
                          <h5 className="fs-14 my-1 fw-normal">
                            {item.margin}
                          </h5>
                        </td>
                        <td>
                          <h5 className="fs-14 my-1 fw-normal">
                            {item.leverage}x
                          </h5>
                        </td>
                        <td>
                          <h5 className="fs-14 my-1 fw-normal">
                            {moment(item.open_at.Time).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                          </h5>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center">
                        <span className="text-muted">No Data</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="d-flex align-items-center justify-content-center mt-4 pt-2">
              <Link to="/position" className="btn btn-primary  w-100">
                See All Positions
              </Link>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RecentOpenedPositions;
