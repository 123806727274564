import React from "react";
import { Link } from "react-router-dom";
import { Card, CardHeader, CardBody, Button } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "../Common/Pagination";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { toastRequest } from "../../utils/toastRequest";
import { isEmpty } from '../../utils/isEmpty'

function PaginatedTable({ page, setPage, total_page, traders }) {
  const store = useSelector((state) => state.User);

  async function copyClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
      toast("Copied !", {
        position: "top-center",
        hideProgressBar: true,
        className: "bg-success text-white",
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function copyLink(id) {
    await navigator.clipboard.writeText(
      "https://exchange.zexters.io/register?ib=" + store.user.ib_id + "&ib_marketing_channel=" + id
    );
    await toastRequest("Copied !", "bg-soft-success text-center");
  }

  let render_traders = isEmpty(traders) ? "" : traders.map((item) => (
    <tr key={item.id}>
      <th scope="row">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name="chk_child"
            value="option1"
          />
        </div>
      </th>
      <td className="id" style={{ display: "none" }}>
        <Link to="#" className="fw-medium link-primary">
          #
        </Link>
      </td>
      <td className="customer_name">
        <i
          onClick={() => copyClipboard(item.id)}
          style={{ cursor: "pointer" }}
          className="ri-file-copy-line"
        ></i>
        {item.id}
      </td>
      <td>
        <Button
          onClick={() => copyLink(item.id)}
          color="success"
          className="btn-label btn-sm"
        >
          {" "}
          <i className="ri-clipboard-line label-icon align-middle fs-16 me-2"></i>
          Copy
        </Button>
        {/* https://exchange.zexters.io/register?ib={store.user.ib_id}&ib_marketing_channel={item.id} */}
      </td>
      <td>{item.name}</td>
      <td>{item.platform}</td>
      <td className="date">
        <Moment calendar date={item.created_at.Time} />
      </td>
      {/* <td className="date"><Moment format="D, M YYYY">{item.created_at}</Moment></td> */}
    </tr>
  ));

  return (
    <Card>
      <CardHeader>
        <h4 className="card-title mb-0">Marketing Channel List</h4>
      </CardHeader>
      <ToastContainer autoClose={1000} />
      <CardBody>
        <div id="customerList">
          <div className="table-responsive table-card mb-1">
            <table
              className="table align-middle table-nowrap"
              id="customerTable"
            >
              <thead className="table-light">
                <tr>
                  <th scope="col" style={{ width: "50px" }}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="checkAll"
                        value="option"
                      />
                    </div>
                  </th>
                  <th className="sort" data-sort="customer_name">
                    Marketing Channel ID
                  </th>
                  <th className="sort" data-sort="phone">
                    Link
                  </th>
                  <th className="sort" data-sort="email">
                    Name
                  </th>
                  <th className="sort" data-sort="phone">
                    Platform
                  </th>
                  <th className="sort" data-sort="phone">
                    Created At
                  </th>
                </tr>
              </thead>
              <tbody className="list form-check-all">{render_traders}</tbody>
            </table>
            <div className="noresult" style={{ display: "none" }}>
              <div className="text-center">
                <lord-icon
                  src="https://cdn.lordicon.com/msoeawqm.json"
                  trigger="loop"
                  colors="primary:#121331,secondary:#08a88a"
                  style={{ width: "75px", height: "75px" }}
                ></lord-icon>
                <h5 className="mt-2">Sorry! No Result Found</h5>
                <p className="text-muted mb-0">
                  We've searched more than 150+ Orders We did not find any
                  orders for you search.
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <div className="flex-shrink-0 mt-1">
              <div className="text-muted">
                Showing <span className="fw-semibold">{page}</span> of{" "}
                <span className="fw-semibold">{total_page}</span> Results
              </div>
            </div>
            <div className="pagination-wrap hstack gap-2">
              <Pagination
                setPage={setPage}
                initial_page={page}
                total_page={total_page}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default PaginatedTable;
