import React, { useEffect, useState } from 'react'
import { Row, Col, Label, Input, Card, CardBody, CardHeader, Button, Spinner } from 'reactstrap'
// import PreviewCardHeader from '../../../components/Common/PreviewCardHeader'
import Select from "react-select";
import { getWithdrawnRate } from '../../../store/withdrawrate/action'
import { isEmpty } from '../../../utils/isEmpty'
import { onChangeAllowNumberOnly } from '../../../utils/validateNumber'
import { WithdrawnComission } from '../../../api/payment'
import { toast, ToastContainer } from 'react-toastify';

export default function WithdrawnForm() {
    const [selectedSingle, setSelectedSingle] = useState(null);
    const [account_number, setAccountNumber] = useState('');
    const [amount, setAmount] = useState('');
    const [withdrawn_rate, setWithdrawnRate] = useState(null);
    const [loading, setLoading] = useState(false);

    const Banks = [
        { value: "KBANK", label: "Kasikorn Bank (Kbank)" },
        { value: "T_SCB", label: "SCB" },
        { value: "BBL", label: "Bangkok Bank (BBL)" },
        { value: "BAY", label: "Krungsri" },
        { value: "KTB", label: "Krung Thai Bank (KTB)" },
        { value: "TTB", label: "TMB Thanachart" },
        { value: "T_UOB", label: "UOB" },
        { value: "T_CIMB", label: "CIMB" },
        { value: "LHBANK", label: "LH Bank" },
        { value: "GSB", label: "Government Savings Bank" },
        { value: "KKP", label: "Kiatnakin" },
        { value: "CITI", label: "Citibank" },
        { value: "GHB", label: "Government Housing Bank" },
        {
            value: "BAAC",
            label: "Bank for Agriculture and Agricultural Cooperatives",
        },
        { value: "IBANK", label: "Islamic Bank of Thailand" },
        { value: "TISCO", label: "TISCO Bank" },
    ];

    function handleSelectSingle(data) {
        setSelectedSingle(data);
    }

    async function onSubmitTransferMoney(e) {
        e.preventDefault()
        setLoading(true)
        try {
            const body = {
                amount: Number(amount),
                account_number,
                bank: selectedSingle.value
            }
            const data = await WithdrawnComission(body)
            setLoading(false)
            toast.success("Successfully Making Request ! Check The Status on Transaction History", { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })
            window.location = "/paymentgateway/transfer-inout"
        } catch (error) {
            setLoading(false)
            toast.error(error, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })
        }
    }

    async function fetchWithdrawnRate() {
        try {
            const data = await getWithdrawnRate()
            setWithdrawnRate(data)
        } catch (error) {
            console.log(error);

        }
    }

    useEffect(() => {
        fetchWithdrawnRate()
    }, [])


    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <CardHeader>
                        <h3>Bank Transfer</h3>
                    </CardHeader>
                    <ToastContainer autoClose={5000} />
                    <CardBody className="card-body">
                        <div className="live-preview">
                            <form onSubmit={onSubmitTransferMoney}>
                                <Row className="gy-4">
                                    <Col xl={12} >
                                        <div>
                                            <Label htmlFor="choices-single-default" className="form-label text-muted">Bank</Label>
                                            <Select
                                                value={selectedSingle}
                                                onChange={handleSelectSingle}
                                                options={Banks}
                                            />

                                            {/* <Label htmlFor="basiInput" className="form-label">Bank</Label>
                                        <Input type="password" className="form-control" id="basiInput" /> */}
                                        </div>
                                    </Col>
                                    <Col xl={12} >
                                        <div>
                                            <Label htmlFor="basiInput" className="form-label">Bank Account</Label>
                                            <Input value={account_number} onChange={(e) => onChangeAllowNumberOnly(e.target.value, setAccountNumber)} type="text" className="form-control" id="basiInput" />
                                        </div>
                                    </Col>
                                    <Col xl={12} >
                                        <div>
                                            <Label htmlFor="basiInput" className="form-label">Amount</Label>
                                            <Input value={amount} onChange={(e) => onChangeAllowNumberOnly(e.target.value, setAmount)} type="text" className="form-control" id="basiInput" />
                                        </div>
                                        <div className='mt-4'>
                                            <span className='text-warning'>USD : </span>
                                            {!isEmpty(withdrawn_rate) ? (Number(amount) / Number(withdrawn_rate)).toFixed(2) : "0.00"}
                                        </div>
                                    </Col>
                                    <Col xl={12} >
                                        <div className='d-flex justify-content-end'>
                                            {loading ? (
                                                <div className="text-end">
                                                    <Button color="primary" className="btn-load" outline>
                                                        <span className="d-flex align-items-center">
                                                            <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>
                                                            <span className="flex-grow-1 ms-2">
                                                                Loading...
                                                            </span>
                                                        </span>
                                                    </Button>
                                                </div>
                                            ) : (
                                                <div className="text-end">
                                                    <button type="submit" className="btn btn-primary">Withdrawn</button>
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                        {/* <div className="d-none code-view">
                    <pre className="language-markup" style={{ height: "352px" }}>
                        <code>
                            <InputExample />
                        </code>
                    </pre>
                </div> */}
                    </CardBody>
                </Card>
            </Col>

        </Row>
    )
}
