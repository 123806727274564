import React, { useState } from "react";
import { Card, CardBody, Label, Col, Input, Row, Button } from "reactstrap";

export function TraderFilter({ onSearch }) {
  const initialValues = {
    id: "",
    email: "",
    phone: "",
  };

  const [filters, setFilters] = useState(initialValues);

  const handleChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(filters);
  };

  const handleReset = () => {
    setFilters(initialValues);
    onSearch(initialValues);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <Card>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={4} md={6}>
              <div className="mb-3">
                <Label htmlFor="traderId" className="form-label text-muted">
                  Trader ID
                </Label>
                <Input
                  type="text"
                  name="id"
                  value={filters.id}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  className="form-control"
                  placeholder="Enter Trader ID"
                />
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="mb-3">
                <Label htmlFor="email" className="form-label text-muted">
                  Email
                </Label>
                <Input
                  type="text"
                  name="email"
                  value={filters.email}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  className="form-control"
                  placeholder="Enter Email"
                />
              </div>
            </Col>

            <Col lg={4} md={6} className="text-end my-auto ">
              <div className="hstack gap-2  mt-2">
                <Button
                  type="button"
                  color="danger"
                  outline
                  onClick={handleReset}
                  className="w-50"
                >
                  <i className="ri-restart-line align-bottom me-1"></i>
                  Reset
                </Button>
                <Button type="submit" color="success" className="w-50">
                  <i className="ri-search-line align-bottom me-1"></i>
                  Search
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </CardBody>
    </Card>
  );
}
