import { useState, useEffect } from "react";
import { createAdmin } from "../../../api/admin"; // Updated import path
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Wrapper from "../../../components/Common/Wrapper";

const AdminEditingForm = () => {
  const { id } = useParams();
  const store = useSelector((state) => state.User);

  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState({ type: "", content: "" });

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    repassword: "",
    ib_id: "",
  });

  useEffect(() => {
    if (store?.user?.ib_id) {
      setFormData((prev) => ({
        ...prev,
        ib_id: store.user.ib_id,
      }));
    }
  }, [store?.user?.ib_id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setMessage({ type: "", content: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.repassword) {
      setMessage({ type: "danger", content: "Passwords do not match" });
      return;
    }

    try {
      await createAdmin(formData);
      setMessage({ type: "success", content: "Admin updated successfully!" });
      setIsEditing(false);
    } catch (error) {
      setMessage({
        type: "danger",
        content: error.response?.data?.message || "Failed to update admin",
      });
    }
  };

  return (
    <Wrapper>
      <div className="card border-secondary">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h5 className="mb-0">Update Admin User</h5>
            {!isEditing ? (
              <button
                type="button"
                onClick={() => setIsEditing(true)}
                className="btn btn-soft-warning"
              >
                <i className="ri-edit-2-fill align-middle me-1"></i>
                Edit Details
              </button>
            ) : (
              <div className="d-flex gap-2">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="btn btn-soft-success"
                >
                  <i className="ri-save-line align-middle me-1"></i>
                  Save Changes
                </button>
                <button
                  className="btn btn-soft-danger btn-sm"
                  onClick={() => setIsEditing(false)}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>

          {message.content && (
            <div className={`alert alert-${message.type} mb-3`} role="alert">
              {message.content}
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                {/* Left Column - Account Information */}
                <div className="mb-4">
                  <h6 className="text-muted mb-3">Account Information</h6>
                  <div className="mb-3">
                    <label className="form-label">IB ID</label>
                    <input
                      type="text"
                      name="ib_id"
                      className="form-control bg-dark text-muted"
                      value={formData.ib_id}
                      disabled
                      readOnly
                    />
                  </div>
                  <div className="mt-3">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      name="username"
                      className="form-control bg-dark text-muted"
                      value={formData.username}
                      onChange={handleInputChange}
                      disabled={true}
                      readOnly
                      placeholder="admin@example.com"
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                {/* Right Column - Security */}
                <div className="mb-4">
                  <h6 className="text-muted mb-3">Security</h6>

                  <div className="mb-3">
                    <label className="form-label">New Password</label>
                    <input
                      type="password"
                      name="password"
                      className="form-control bg-dark text-muted"
                      value={formData.password}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                      placeholder="Enter new password"
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Confirm New Password</label>
                    <input
                      type="password"
                      name="repassword"
                      className="form-control bg-dark text-muted"
                      value={formData.repassword}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                      placeholder="Confirm new password"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Wrapper>
  );
};

export default AdminEditingForm;
