import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../components/Common/BreadCrumb';
import { Link } from 'react-router-dom';
import { getSubIBSitemap } from '../../../api/subib';
import { getIBDetailById } from '../../../api/IB';
import { isEmpty } from '../../../utils/isEmpty';

const DashboardSubIBDetail = () => {
  const [ibShare, setIBShare] = useState('');
  const [sitemap, setSitemap] = useState([]);
  document.title = "IB Sitemap | IB Sitemap - Zexter Admin Dashboard";

  async function getIBSitemap() {
    try {
      const [sitemapData, ibDetail] = await Promise.all([
        getSubIBSitemap(),
        getIBDetailById()
      ]);

      setIBShare(ibDetail.share);
      setSitemap(sitemapData);
    } catch (error) {
      console.error("Error fetching IB sitemap:", error);
    }
  }

  useEffect(() => {
    getIBSitemap();
  }, []);

  return (
    <div>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="IB Sitemap" pageTitle="Sub IB" link={"/sub"} />

          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title mb-0">IB Sitemap</h4>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm={12}>
                      <div className="verti-sitemap">
                        <ul className="list-unstyled mb-0">
                          <div className="first-list">
                            <div className="list-wrap">
                              <Link to="#" className="fw-medium text-success">Your IB - ({ibShare} %)</Link>
                            </div>
                            <ul className="second-list list-unstyled">
                              <IBSitemap sitemap={sitemap} />
                            </ul>
                          </div>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

const IBSitemap = ({ sitemap }) => {
  const renderChildIB = (ib) => {
    return !ib ? "" : (
      <ul className="second-list list-unstyled">
        {ib.map((childIB, index) => (
          <li key={index}>
            <Link to="#">{childIB.name} - ({childIB.share}%)</Link>
            {!isEmpty(childIB.IBs) && renderChildIB(childIB.IBs)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    sitemap.map((item) => (
      <>
        <li>
          <Link to="#">{item.name} - ({item.share}) %</Link>
        </li>
        {renderChildIB(item.IBs)}
      </>
    ))
  )

  // return (
  //   sitemap.map((ib, index) => (
  //     <div key={index} className="first-list">
  //       <div className="list-wrap">
  //         <Link to="#" className="fw-medium">
  //           {ib.name} - ({ib.share}%)
  //         </Link>
  //       </div>
  //       {!isEmpty(ib.ibs) && renderChildIB(ib)}
  //     </div>
  //   ))
  // );
};

export default DashboardSubIBDetail;
