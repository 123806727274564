import React, { useState } from "react";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  TabContent,
  TabPane,
} from "reactstrap";

const PasswordTap = () => {
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");

  const validatePassword = (password) => {
    const minLength = 8;

    if (password.length < minLength) {
      return "Password must be at least 8 characters long";
    }
    return "";
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear errors when user starts typing
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
    setSubmitMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrors({ newPassword: "", confirmPassword: "" });
    setSubmitMessage("");

    // Validate new password
    const passwordError = validatePassword(formData.newPassword);
    if (passwordError) {
      setErrors((prev) => ({ ...prev, newPassword: passwordError }));
      setIsSubmitting(false);
      return;
    }

    // Check if passwords match
    if (formData.newPassword !== formData.confirmPassword) {
      setErrors((prev) => ({
        ...prev,
        confirmPassword: "Passwords do not match",
      }));
      setIsSubmitting(false);
      return;
    }

    try {
      setSubmitMessage("Password updated successfully!");

      // Clear form
      setFormData({
        newPassword: "",
        confirmPassword: "",
      });
    } catch (error) {
      setSubmitMessage("Failed to update password. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card className="border-secondary">
      <CardBody>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h5 className="mb-0">Reset Password</h5>

          <div className="d-flex gap-2">
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-soft-success"
              disabled={isSubmitting}
            >
              <i className="ri-edit-2-fill align-middle me-1"></i>
              {isSubmitting ? "Updating..." : "Confirm"}
            </button>
          </div>
        </div>

        {submitMessage && (
          <div
            className={`alert ${
              submitMessage.includes("success")
                ? "alert-success"
                : "alert-danger"
            } mb-3`}
          >
            {submitMessage}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={12}>
              <div className="mb-4">
                <h6 className="text-muted mb-3 text-red-500">
                  Change Password
                </h6>

                <div className="mb-3">
                  <label className="form-label">New Password</label>
                  <input
                    type="password"
                    name="newPassword"
                    className={`form-control bg-dark text-muted ${
                      errors.newPassword ? "is-invalid" : ""
                    }`}
                    value={formData.newPassword}
                    onChange={handleInputChange}
                  />
                  {errors.newPassword && (
                    <div className="invalid-feedback">{errors.newPassword}</div>
                  )}
                </div>

                <div className="mb-3">
                  <label className="form-label">Confirm New Password</label>
                  <input
                    type="password"
                    name="confirmPassword"
                    className={`form-control bg-dark text-muted ${
                      errors.confirmPassword ? "is-invalid" : ""
                    }`}
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                  />
                  {errors.confirmPassword && (
                    <div className="invalid-feedback">
                      {errors.confirmPassword}
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </CardBody>
    </Card>
  );
};

export default PasswordTap;
