import { useState, useEffect } from "react";
import { createAdmin } from "../../../api/admin";
import Wrapper from "../../../components/Common/Wrapper";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AdminCreationForm = () => {
  const navigate = useNavigate();

  const [message, setMessage] = useState({ type: "", content: "" });
  const store = useSelector((state) => state.User);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    repassword: "",
  });

  useEffect(() => {
    if (store?.user?.ib_id) {
      setFormData((prev) => ({
        ...prev,
        ib_id: store.user.ib_id,
      }));
    }
  }, [store?.user?.ib_id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setMessage({ type: "", content: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.repassword) {
      setMessage({ type: "danger", content: "Passwords do not match" });
      return;
    }

    try {
      await createAdmin(formData);
      setMessage({ type: "success", content: "Admin created successfully!" });
      setFormData({
        username: "",
        password: "",
        repassword: "",
      });
    } catch (error) {
      setMessage({
        type: "danger",
        content: error.response?.data?.message || "Failed to create admin",
      });
    }
  };

  return (
    <Wrapper>
      <div className="card border-secondary">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h5 className="mb-0">Create Admin User</h5>
            <button
              type="button"
              onClick={() => navigate("/admin")}
              className="btn btn-soft-danger"
            >
              Back
            </button>
          </div>

          {message.content && (
            <div className={`alert alert-${message.type} mb-3`} role="alert">
              {message.content}
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                {/* Left Column - Account Information */}
                <div className="mb-4">
                  <h6 className="text-muted mb-3">Account Information</h6>
                  <div className="mb-3">
                    <div className="mt-3">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        name="username"
                        className="form-control bg-dark text-muted"
                        value={formData.username}
                        onChange={handleInputChange}
                        placeholder="admin@example.com"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                {/* Right Column - Security */}
                <div className="mb-4">
                  <h6 className="text-muted mb-3">Security</h6>

                  <div className="mb-3">
                    <label className="form-label">Password</label>
                    <input
                      type="password"
                      name="password"
                      className="form-control bg-dark text-muted"
                      value={formData.password}
                      onChange={handleInputChange}
                      placeholder="Enter password"
                    />
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Confirm Password</label>
                    <input
                      type="password"
                      name="repassword"
                      className="form-control bg-dark text-muted"
                      value={formData.repassword}
                      onChange={handleInputChange}
                      placeholder="Confirm password"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-11 "></div>
              <div className=" col-md-1">
                {" "}
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="btn btn-soft-success align-items-end justify-content-end"
                >
                  <i className="ri-save-line align-middle me-1"></i>
                  Confirm
                </button>
              </div>
            </div>{" "}
          </form>
        </div>
      </div>
    </Wrapper>
  );
};

export default AdminCreationForm;
