import React, { useState } from "react";
import { Col, Container, Row, Form, Label, Input, Card, CardBody, Button, Spinner } from "reactstrap";
import { Helmet } from "react-helmet";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import BreadCrumb from '../../../components/Common/BreadCrumb'
import { createMarketingChannel } from "../../../api/marketing-channel";

const CreateMarketingChannel = () => {

    const [name, setName] = useState(""),
        [platform, setPlatform] = useState(""),
        [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    async function onSubmitAddWallet(e) {
        e.preventDefault()
        setLoading(true)
        try {
            const body = {
                name,
                platform
            }
            await createMarketingChannel(body);
            navigate('/marketing-channel', { state: { toastMessage: "Channel Created Successfully!" } });
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast(error, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' })
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Create Marketing Channel - Zexter Admin Dashboard</title>
            </Helmet>
            <ToastContainer autoClose={5000} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={"Create Marketing Channel"} pageTitle={"Marketing Channel"} link={"/marketing-channel"} />
                    <Card className="col-lg-6 col-md-12">
                        <CardBody>
                            <Form onSubmit={onSubmitAddWallet}>
                                <Row>
                                    <Col md={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="firstNameinput" className="form-label">Name</Label>
                                            <Input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Enter Marketing Name" id="firstNameinput" />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="choices-single-no-sorting" className="form-label">
                                                Platform
                                            </Label>
                                            <Input type="text" value={platform} onChange={(e) => setPlatform(e.target.value)} className="form-control" placeholder="Enter Platform" id="firstNameinput" />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        {loading ? (
                                            <div className="text-end">
                                                <Button color="primary" className="btn-load" outline>
                                                    <span className="d-flex align-items-center">
                                                        <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>
                                                        <span className="flex-grow-1 ms-2">
                                                            Loading...
                                                        </span>
                                                    </span>
                                                </Button>
                                            </div>
                                        ) : (
                                            <div className="text-end">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default CreateMarketingChannel;
