import { toast } from "react-toastify";

export const toastRequest = async (text, color) => {
  toast(
    text, 
    { 
      position: 'top-center', 
      hideProgressBar: true, 
      className: `${color} text-white`
    }
  );
}