import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { copyClipboard } from '../../../utils/clipboard'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { UpdateWallets } from '../../../api/wallet'
import { toast } from 'react-toastify'
import Moment from 'react-moment'


function TableTransaction({ wallets }) {

    async function onClickUpdateWalletStatus(id, current_status) {
        try {
            const body = {
                status: !current_status
            }
            await UpdateWallets(id, body)
            toast.success("successfully ! Update Wallet Status")
        } catch (error) {
            toast.danger(error)
        }
    }

    function switchStatus(status) {
        switch (status) {
            case "success":
                return <span className={"badge badge-soft-" + "success" + " p-2"}>Success</span>
            case "pending":
                return <span className={"badge badge-soft-" + "warning" + " p-2"}>Processing</span>
            case "failed":
                return <span className={"badge badge-soft-" + "danger" + " p-2"}>Failed</span>
            default:
                return <span className={"badge badge-soft-" + "success" + " p-2"}>unknow status</span>
        }
    }

    let render_wallets = !wallets.data ?
        (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                {/* <h1>No Data</h1> */}
            </div>
        ) : wallets.data.map((item) => (
            <tr key={item.id}>
                <th scope="row">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                    </div>
                </th>
                <td className="customer_name">
                    <i onClick={() => copyClipboard(item.id)} style={{ cursor: "pointer" }} className="ri-file-copy-line"></i> {item.id}
                </td>
                <td>
                    {item.type === "withdrawn" ?
                        <span className={"badge badge-soft-" + "warning" + " p-2"}>Withdrawn</span>
                        :
                        <span className={"badge badge-soft-" + "success" + " p-2"}>{item.type}</span>
                    }
                </td>
                <td>
                    {item.method}
                </td>
                <td>{item.amount.toFixed(2)}</td>
                <td>{switchStatus(item.status)}</td>
                <td><Moment calendar date={item.created_at} /></td>
            </tr>
        ))

    return (
        <Card>
            <CardHeader>
                <h4 className="card-title mb-0">Transaction List</h4>
            </CardHeader>

            <CardBody>
                <div id="customerList">
                    <div className="table-responsive table-card mb-1">
                        <table className="table align-middle table-nowrap" id="customerTable">
                            <thead className="table-light">
                                <tr>
                                    <th scope="col" style={{ width: "50px" }}>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                        </div>
                                    </th>
                                    <th className="sort" data-sort="wallet_id">Transaction ID</th>
                                    <th className="sort" data-sort="address">Type</th>
                                    <th className="sort" data-sort="address">Method</th>
                                    <th className="sort" data-sort="balance">Amount</th>
                                    <th className="sort" data-sort="balance">Status</th>
                                    <th className="sort" data-sort="created">Created At</th>
                                </tr>
                            </thead>
                            <tbody className="list form-check-all">
                                {render_wallets}
                            </tbody>
                        </table>
                        <div className="noresult" style={{ display: "none" }}>
                            <div className="text-center">
                                <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                    colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                </lord-icon>
                                <h5 className="mt-2">Sorry! No Result Found</h5>
                                <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                    orders for you search.</p>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end">
                        <div className="pagination-wrap hstack gap-2">
                            <Link className="page-item pagination-prev disabled" to="#">
                                Previous
                            </Link>
                            <ul className="pagination listjs-pagination mb-0"></ul>
                            <Link className="page-item pagination-next" to="#">
                                Next
                            </Link>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default TableTransaction