import {
  LOGIN,
  LOGOUT,
} from "./actionTypes";

const initialState = {
  isAuthenticated: false,
  user: null,
  loading: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      state = {
        ...state,
        user: action.payload.user,
        isAuthenticated: true,
      };
      break;
    case LOGOUT:
      state = {
        ...state,
        ...state,
        user: null,
        isAuthenticated: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default user;
