import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";

import { getPositionHistory } from "../../../store/trader/positionhistory/action";
const PositionTap = () => {
  const { id } = useParams();
  const [sortConfig, setSortConfig] = useState({
    key: "datetime",
    direction: "desc",
  });

  const handleSort = (key) => {
    setSortConfig((current) => ({
      key,
      direction:
        current.key === key && current.direction === "asc" ? "desc" : "asc",
    }));
  };

  const [positionHistoryData, setPositionHistoryData] = useState([]);
  const [filterType, setFilterType] = useState("all");
  const [filterSymbol, setFilterSymbol] = useState("all");
  const [uniqueSymbols, setUniqueSymbols] = useState([]);

  useEffect(() => {
    async function fetchPositionHistory() {
      try {
        const result = await getPositionHistory(id);
        if (result) {
          setPositionHistoryData(result);
          // Extract unique symbols for the filter dropdown
          const symbols = [...new Set(result.map((item) => item.symbol))];
          setUniqueSymbols(symbols);
        }
      } catch (error) {
        console.error("Error fetching position history:", error);
        setPositionHistoryData([]);
      }
    }
    fetchPositionHistory();
  }, [id]);
  const handleTypeFilter = (e) => {
    setFilterType(e.target.value);
  };

  const handleSymbolFilter = (e) => {
    setFilterSymbol(e.target.value);
  };
  const getFilteredData = () => {
    return positionHistoryData.filter((position) => {
      const matchesType =
        filterType === "all" ||
        (filterType === "buy" && position.order_type === 0) ||
        (filterType === "sell" && position.order_type === 1);
      const matchesSymbol =
        filterSymbol === "all" || position.symbol === filterSymbol;
      return matchesType && matchesSymbol;
    });
  };

  return (
    <Card className="border-secondary">
      <CardBody>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h5 className="mb-0">Position History</h5>
          <div className="d-flex gap-2">
            <select
              className="form-select form-select-sm"
              style={{ width: "120px" }}
              value={filterType}
              onChange={handleTypeFilter}
            >
              <option value="all">All Types</option>
              <option value="buy">Buy Only</option>
              <option value="sell">Sell Only</option>
            </select>
            <select
              className="form-select form-select-sm"
              style={{ width: "120px" }}
              value={filterSymbol}
              onChange={handleSymbolFilter}
            >
              <option value="all">All Symbols</option>
              {uniqueSymbols.map((symbol) => (
                <option key={symbol} value={symbol}>
                  {symbol}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-dark table-borderless mb-0">
            <thead>
              <tr>
                <th>
                  <button
                    className="btn btn-link text-muted p-0 text-decoration-none d-flex align-items-center"
                    onClick={() => handleSort("open_at")}
                  >
                    Date Time
                    {sortConfig.key === "open_at" && (
                      <i
                        className={`ri-arrow-${
                          sortConfig.direction === "asc" ? "up" : "down"
                        }-line ms-1`}
                      ></i>
                    )}
                  </button>
                </th>
                <th>Type</th>
                <th>Symbol</th>
                <th>Size</th>
                <th>Open Price</th>
                <th>Spread</th>
                <th>Margin</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {!positionHistoryData || positionHistoryData.length === 0 ? (
                <tr>
                  <td colSpan="8" className="text-center">
                    <div className="d-flex flex-column align-items-center py-4">
                      <i className="ri-file-list-3-line fs-2 text-muted mb-2"></i>
                      <div className="text-muted">
                        No position history available
                      </div>
                    </div>
                  </td>
                </tr>
              ) : getFilteredData().length === 0 ? (
                <tr>
                  <td colSpan="8" className="text-center">
                    <div className="d-flex flex-column align-items-center py-4">
                      <i className="ri-filter-off-line fs-2 text-muted mb-2"></i>
                      <div className="text-muted">
                        No results found for the selected filters
                      </div>
                    </div>
                  </td>
                </tr>
              ) : (
                getFilteredData().map((position) => (
                  <tr key={position.id}>
                    <td>{new Date(position.open_at.Time).toLocaleString()}</td>
                    <td>
                      <span
                        className={`badge bg-soft-${
                          position.order_type === 0 ? "success" : "danger"
                        } text-${
                          position.order_type === 0 ? "success" : "danger"
                        }`}
                      >
                        {position.order_type === 0 ? "BUY" : "SELL"}
                      </span>
                    </td>
                    <td>{position.symbol}</td>
                    <td
                      className={`text-${
                        position.order_type === 0 ? "success" : "danger"
                      }`}
                    >
                      {position.size}
                    </td>
                    <td
                      className={`text-${
                        position.order_type === 0 ? "success" : "danger"
                      }`}
                    >
                      {position.open_price}
                    </td>
                    <td
                      className={`text-${
                        position.order_type === 0 ? "success" : "danger"
                      }`}
                    >
                      {position.spread}
                    </td>
                    <td>{position.margin}</td>
                    <td>
                      <span
                        className={`badge bg-soft-${
                          position.is_closed.Bool ? "danger" : "success"
                        } text-${
                          position.is_closed.Bool ? "danger" : "success"
                        }`}
                      >
                        {position.is_closed.Bool ? "CLOSED" : "OPEN"}
                      </span>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
};

export default PositionTap;
