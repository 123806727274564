import { setAuthorization } from "../../../helpers/api_helper";
import {
  LOGIN,
  LOGOUT
} from "./actionTypes";

export const login_ib = (user, history) => {
  return {
    type: LOGIN,
    payload: { user, history },
  };
};

export const logout_ib = history => {
  localStorage.removeItem("jwt")
  setAuthorization(false)
  return {
    type: LOGOUT,
    payload: { history },
  };
};

